import './App.css';
import bg from './assets/shity_background.png'
import {ReactComponent as Logo} from './assets/Logo.svg'
import {ReactComponent as LogoSm} from './assets/LogoSm.svg'
import axios from 'axios';
import { Fragment, useEffect, useState } from "react";
import {Helmet} from 'react-helmet'
import { Document, Page } from 'react-pdf';


function Header(props) {
  function FakeRedirect(e) {
    if (window.location.pathname != '/') {
      window.history.replaceState(null, null, "/")
    }
    e.preventDefault();
    props.setPath(e.target.getAttribute('href'))
    let collection = [...document.getElementsByClassName('nav_element')]
    collection.forEach(el => {
      el.classList.remove('nav_selected')
    })
  }

  return (
    <div className="app_header">
      <Helmet>
        <title>СЗУАП</title>
        <meta name="description" content="СЗУАП" />
      </Helmet>
      <div className="app_header_nav">
          <div className="app_header_nav__contacts">
            <a href='tel:812-347-59-81' id='phone'>(812) 347-59-81</a>
            <a id='city'>Санкт-Петербург</a>
            <a href='activity' className='nav_element' onClick={FakeRedirect}>Услуги</a>
          </div>
          <a href='deal' className='nav_element' onClick={FakeRedirect}>Деятельность</a>
          <a href='contacts' className='nav_element' onClick={FakeRedirect}>Контакты</a>
      </div>
      <div className="app_header_nav_sm">
          <div className="app_header_nav__contacts">
            <a id='city'>Санкт-Петербург</a>
            <a href='activity' className='nav_element' onClick={FakeRedirect}>Услуги</a>
          </div>
          <div className="app_header_nav__contacts">
            <LogoSm />
            <a href='deal' className='nav_element' onClick={FakeRedirect}>Деятельность</a>
          </div>
          <div className="app_header_nav__contacts">
            <a href='tel:812-347-59-81' id='phone'>(812) 347-59-81</a>
            <a href='contacts' className='nav_element' onClick={FakeRedirect}>Контакты</a>
          </div>
      </div>
      <div className="app_header_nav_xs">
        <div style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-around'
        }}>
          <LogoSm />
        </div>
        <div className="app_header_nav__contacts sx_menu_show">
            <a href='activity' className='nav_element' onClick={FakeRedirect}>Услуги</a>
            <a href='deal' className='nav_element' onClick={FakeRedirect}>Деятельность</a>
            <a href='contacts' className='nav_element' onClick={FakeRedirect}>Контакты</a>
          </div>
      </div>
    </div>
  )
}

function Nav(props) {
  return (
    <div className="app_nav">
      <div className="app_nav_description">
        <h2>О Компании</h2>
        <Helmet>
          <title>СЗУАП</title>
          <meta name="description" content="ООО СЗУАП Группа компаний Северо-Западное Управление Антикризисных Проблем осуществляет деятельность в области аудита и консалтинга более 20 лет и входит в число крупнейших аудиторского-консалтинговых групп России." />
        </Helmet>
        <div className="desc_plan" dangerouslySetInnerHTML={{__html: props.description}}></div>
      </div>
    </div>
  )
}

function Activity(props) {
  return (
    <div className='app_main_activity'>
      <Helmet>
        <title>Услуги компании СЗУАП</title>
        <meta name="description" content={props.title}/>
      </Helmet>
      <span>{props.title}</span>
      <div>
        <img alt="activity" src={props.svg_path} />
      </div>
    </div>
  )
}

function Post(props) {
  function LoadDoc() {
    window.location.href = `/detail_deal/${props.id}`
  }

  return (
    <div className='app_main_post' onClick={LoadDoc}>
      <Helmet>
        <title>Деятельность</title>
        <meta name="description" content="В данном разделе раскрывается информация о деятельности ООО СЗУАП"/>
      </Helmet>
      <span>{props.title}</span>
      <div></div>
      {/* <span>{props.date.split('T')[0]}</span> */}
    </div>
  )
}

function Main(props) {
  return (
    <div className="app_main">
      {props.children}
    </div>
  )
}

export default function App() {
  const [error, setError] = useState(null);
  const [activites, setActivity] = useState([])
  const [content, setContent] = useState([])
  const [content_detail, setContentDetail] = useState({})
  const [aboutus, setAboutUs] = useState('')
  const [contacts, setContacts] = useState('')
  const [path, setPath] = useState(undefined)

  const [mapLoaded, setMapLoaded] = useState(false)

  useEffect(() => {
    if (path == undefined) {
      if (window.location.pathname.split('/')[1] == 'detail_deal') {
        setPath('detail_deal')
      } else {
        setPath('activity')
      }
    } else {
      setPath(path)
    }
  }, [])

  useEffect(() => {
    if (document.querySelector(`[href="${path}"]`) != null) {
      document.querySelectorAll(`[href="${path}"]`).forEach(el => {
        el.classList.add('nav_selected')
      })
    } 
  }, [path])

  useEffect(() => {

    if (window.location.pathname.split('/')[1] == 'detail_deal') {
      axios
      .get(`https://szuap-audit.ru/api/v2/content/${window.location.pathname.split('/')[2]}`)
      .then((res) => {
        setContentDetail(res.data)
      })
      .catch((error) => setError({err: error, url: 'https://szuap-audit.ru/api/v2/content/'})) 
    }

    axios
      .get('https://szuap-audit.ru/api/v2/aboutus/')
      .then((res) => {
        setAboutUs(res.data[res.data.length - 1].description)
      })
      .catch((error) => setError({err: error, url: 'https://szuap-audit.ru/api/v2/aboutus/'}))

    if (window.location.pathname == '/') {
      axios
        .get('https://szuap-audit.ru/api/v2/content/')
        .then((res) => {
          setContent(res.data)
        })
        .catch((error) => setError({err: error, url: 'https://szuap-audit.ru/api/v2/content/'}));

      axios
        .get('https://szuap-audit.ru/api/v2/activity/')
        .then((res) => {
          setActivity(res.data)
        })
        .catch((error) => setError({err: error, url: 'https://szuap-audit.ru/api/v2/activity/'}));
      axios
        .get('https://szuap-audit.ru/api/v2/contacts/')
        .then((res) => {
          setContacts(res.data[res.data.length - 1].description)
        })
        .catch((error) => setError({err: error, url: 'https://szuap-audit.ru/api/v2/contacts/'}))
    }
  }, [path]);

  useEffect(() => {
    setMapLoaded(false)
    if (path == 'contacts' && mapLoaded == false) {
      setTimeout(() => { loadMap() }, 1000)
    } 
  }, [path])

  let activitesBlock = activites.map(({label, image, image_svg}, index) => {
    let tmp_img = image_svg == null ? image : image_svg
    //console.log(index +1, (index +1) % 2)
    return (index +1) % 4 === 0 ? 
    <Fragment key={index}>
      <Activity title={label} svg_path={tmp_img} />
      <div className='break'></div>
    </Fragment> :
    <Activity key={index} title={label} svg_path={tmp_img} /> 
  })

  let contentBlock = content.map((item, index) => {
    return (index +1) % 4 === 0 ? 
    <Fragment key={index}>
      <Post title={item.title} id={item.id} content={item.content} img_path={item.image} date={item.created_on} file={item.file} />
      <div className='break'></div>
    </Fragment> :
    <Post key={index} id={item.id} title={item.title} content={item.content} img_path={item.image} date={item.created_on} file={item.file} />
  })

  function loadMap() {
    const ymaps = window.ymaps
    ymaps.ready(() => {
      new ymaps.Map('yandex-map', {
          center: [
            59.933983, 30.297129
          ],
          zoom: 15
      }, {
          searchControlProvider: 'yandex#search'
      })
      .geoObjects
        .add(new ymaps.Placemark([59.933983, 30.297129], {
          balloonContent: 'Мы здесь!'
          }, {
              preset: 'islands#icon',
              iconColor: '#3caa3c'
        }))
    },
    setMapLoaded(true))
  }

  function get_detail_pdf(request) {
    if (request != undefined) {
      return request.replace(/^http?\:\/\//i, "https://")
    }
  }

  if (error && error.err) {
    return <div style={{color: '#fff', margin: '20px'}}>
      <p style={{fontSize: '24px'}}>Произошла ошибка: <strong style={{color: 'red'}}>{error.err.message}</strong>
      <br/>"Сломанная" ссылка: <strong style={{color: 'red !important'}}><a href={error.url}>{error.url}</a></strong>
      <br/>Проверьте данные в панеле администрирования!</p>
    </div>;
  }

  return (
    <>
      <Logo className='logo' />
      <div className='gradient_mask'></div>
      <div className='triangle'>
      </div>
      <div className="app">
        <div className="app_body">
          <Header setPath={setPath} />
          {window.location.pathname == '/' &&
            <Main>
              {path == 'activity' &&
                <div id="activites" className='app_main__activity_container'>
                  {activitesBlock}
                </div>
              }      
              {path == 'deal' &&
                <div className='app_main__activity_container'>
                  {contentBlock}
                </div>
              }  
              {path == 'contacts' &&
              <div className='app_main__activity_container'>
                <div className="desc_plan_contacts" dangerouslySetInnerHTML={{__html: contacts}}></div>
                <div className="yandex-map">
                  <div id='yandex-map'>
                  <Helmet>
                    <title>Контакты</title>
                    <meta name="description" content="Контакты ООО СЗУАП"/>
                  </Helmet>
                  </div>
                </div>
              </div>
              }    
            </Main>  
          }
          {window.location.pathname.split('/')[1] == 'detail_deal' &&
            <Main>
              <div className='detail_deal'>
                <h2>{content_detail.title}</h2>
                <iframe src={get_detail_pdf(content_detail.file)} width="100%" height="900px" />
                <a href={get_detail_pdf(content_detail.file)}><span>Скачать файл</span></a>
              </div>
            </Main>
          }
        </div>
        <Nav description={aboutus} />
      </div>
    </>
  )
}
